import React, {useEffect, useState} from "react"
import ThemeNavbar from "./Navbar";
import {useDispatch} from "react-redux";
import {spinnerHandler} from "../../../store/domain/spinner/action";
import {getNotifications} from "../../../services/notification";
import {Col, Row} from "reactstrap";
import moment from "moment";

const NotificationLayer = props => {
  const dispatch = useDispatch()
  const [data, setData] = useState([])


  useEffect(()=>{
    dispatch(spinnerHandler(true))
    getNotifications().then(res => {
      dispatch(spinnerHandler(false))
      setData(res?.result ?? [])
    })
  },[])

  return (
    <Row className={'notification-wrapper'}>
      {data.length > 0 && data.map((obj,index) => {
        return <Col md={12} key={index} className={'layer'}>
          <div className={'d-flex justify-content-space-between t-header'}>
            <p>{`(${index+1})`}</p>
            <p>{moment(new Date(obj.createDate)).format("YYYY-MM-DD HH:mm A")}</p>
          </div>

          <p>{`Title: ${obj?.title ?? 'N/A'}`}</p>
          <p>{`Content: ${obj?.description ?? 'N/A'}`}</p>
          {(index + 1) !== data.length ? <hr /> : null}
        </Col>
      })}
    </Row>
  )

}
export default NotificationLayer;
