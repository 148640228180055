/* eslint-disable*/
import React from "react"
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap"
import * as Icon from "react-feather"
import { history } from "../../../history"
import * as constant from "../../../configs/constant"
import * as actionCreator from "../../../store/domain/auth/action";
import {
  connect,
} from 'react-redux';
import * as commonFunc from "../../../utility/commonFunc";
import CorporateImage from "../../../assets/img/views/profile/pro-pic.png";
import swal from "sweetalert";
import Cookies from "js-cookie";
import {ADMIN_FCM_TOKEN, USER_EMAIL, USER_IMAGE, USER_NAME} from "../../../configs/constant";
import {Bell} from "react-feather";

const signoutHandler = (props) => {
  swal({
    title: commonFunc.sureTxt,
    // icon: warningIcon,
    closeOnClickOutside: false,
    buttons: {
      cancel: 'No',
      dangerMode: {
        text: "Yes",
        value: "action",
        className: "okay-btn"
      }
    }
  })
    .then((value) => {
      switch (value) {
        case "action":
          // props.logoutUser();
          localStorage.removeItem(ADMIN_FCM_TOKEN);
          Cookies.remove(constant.ACCESS_TOKEN);
          // history.push(constant.BASE_ROUTE_PATH+"/login")
          window.location = constant.BASE_ROUTE_PATH+"/login"
          break;
        default:
      }
    })
};

const UserDropdown = props => {
  return (
    <DropdownMenu right>
      {/*<DropdownItem*/}
      {/*  onClick={() => history.push(constant.BASE_ROUTE_PATH+constant.ROUTE_SETTINGS)}*/}
      {/*>*/}
      {/*  <Icon.Settings size={14} className="mr-50" />*/}
      {/*  <span className="align-middle">Settings</span>*/}
      {/*</DropdownItem>*/}
      {/*<DropdownItem divider />*/}
      <DropdownItem
        tag="a"
        href="#"
        onClick={()=>signoutHandler(props)}
      >
        <Icon.Power size={14} className="mr-50" />
        <span className="align-middle">Logout</span>
      </DropdownItem>
    </DropdownMenu>
  )
};

class NavbarUser extends React.PureComponent {
  state = {
    navbarSearch: false,
  };

  componentWillReceiveProps(nextProps) {
    if(nextProps.status !== -1){
      // commonFunc.notifyMessage(nextProps.message,nextProps.status)
      this.props.resetHandler();
    }
    if(!nextProps.loggedInStatus){
      // history.push(constant.BASE_ROUTE_PATH+"/login")
      window.location = constant.BASE_ROUTE_PATH+"/login"

    }
  }

  // d-none
  render() {
    return (
      <ul className="nav navbar-nav navbar-nav-user float-right">
        <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
          <DropdownToggle tag="a" className="nav-link dropdown-user-link">

            <div className="user-nav d-sm-flex ">
              <span className="user-name text-bold-600">{`${localStorage.getItem(USER_NAME)}`}</span>
            </div>
            <span data-tour="user">
              <img
                src={localStorage.getItem(USER_IMAGE) ? localStorage.getItem(USER_IMAGE) : CorporateImage}
                className="half-round nav-logo"
                height="40"
                width="40"
                alt="avatar"
              />
            </span>
          </DropdownToggle>
          <UserDropdown {...this.props} />
        </UncontrolledDropdown>
      </ul>
    )
  }
}
const mapStateToProps = (state) => ({
  loggedInStatus: state.auth.loggedInStatus,
  status: state.auth.status,
  message: state.auth.message,
  isSpinner: state.spinner.isSpinner,
});

const mapDispatchToProps = (dispatch) => ({
  logoutUser: userCredentials => dispatch(actionCreator.logoutUser(userCredentials)),
  resetHandler: () => dispatch(actionCreator.resetWarningHandler())
});

export default connect(mapStateToProps, mapDispatchToProps)(NavbarUser);
