import ApiService from "./apiService";
import {ADMIN_ID, ADMIN_ROLE, MANAGER_ROLE, USER_TYPE} from "../configs/constant";

export async function save(obj, id) {
  const apiObject = {};
  apiObject.method = id > 0 ? 'PUT':'POST';
  apiObject.authentication = true;
  apiObject.endpoint = `user/${id > 0 ?'update':'save'}`;
  apiObject.body = obj;
  apiObject.multipart = true;
  return await ApiService.callApi(apiObject);
}
//
export async function getAll(page, filterText, filterManagerId) {
  const apiObject = {};
  apiObject.method = 'GET';
  apiObject.authentication = true;
  // apiObject.endpoint = `user/gets/${page}/10/true`;
  apiObject.endpoint = filterText || filterManagerId ? `user/get/cleaners/filter/${(!!filterManagerId)}/${filterManagerId}/${filterText ? filterText : 0}/${page}/10/true` : `user/gets/${page}/10/true`;
  apiObject.body = null;
  return await ApiService.callApi(apiObject);
}
// get manager requests
export async function getManagerRequests(page, filterId) {
  const apiObject = {};
  apiObject.method = 'GET';
  apiObject.authentication = true;
  apiObject.endpoint = `user/get/cleaner-activation/${filterId === 1}/${page}/10/true`;
  apiObject.body = null;
  return await ApiService.callApi(apiObject);
  // /true/0/10/true
  // {isPending}/{page_no}/{page_count}/{is_desc}
}
// approve/reject request
export async function manageManagerRequest(body) {
  const apiObject = {};
  apiObject.method = 'PUT';
  apiObject.authentication = true;
  apiObject.endpoint = `user/cleaner-activation`;
  apiObject.body = body;
  return await ApiService.callApi(apiObject);
}
export async function getAllStaff(page, filterFname, filterLname, roleId) {
  const apiObject = {};
  apiObject.method = 'GET';
  apiObject.authentication = true;
  apiObject.endpoint = `user/gets/without_cleaners/${page}/10/true?first_name=${filterFname.trim()}&last_name=${filterLname.trim()}&role_id=${roleId ? roleId : 0}`;
  // apiObject.endpoint = `user/gets/without_cleaners/${page}/10/true/${filterFname.trim()}/${filterLname.trim()}/${roleId ? roleId : 0}`;
  apiObject.body = null;
  return await ApiService.callApi(apiObject);
}
// admins
export async function getAllAdmins() {
  const apiObject = {};
  apiObject.method = 'GET';
  apiObject.authentication = true;
  apiObject.endpoint = `user/gets/ADMIN_ROLE`;
  apiObject.body = null;
  return await ApiService.callApi(apiObject);
}
// clients
export async function getAllManagers() {
  const apiObject = {};
  apiObject.method = 'GET';
  apiObject.authentication = true;
  apiObject.endpoint = `user/gets/SUPERVISOR_ROLE`;
  apiObject.body = null;
  return await ApiService.callApi(apiObject);
}
// managers
export async function getAllSupervisors() {
  const apiObject = {};
  apiObject.method = 'GET';
  apiObject.authentication = true;
  // apiObject.endpoint = `user/gets/SUPERVISOR_ROLE`;
  apiObject.endpoint = `user/gets/MANAGER_ROLE`;
  apiObject.body = null;
  return await ApiService.callApi(apiObject);
}
export async function getAllCleaners() {
  const apiObject = {};
  apiObject.method = 'GET';
  apiObject.authentication = true;
  apiObject.endpoint = localStorage.getItem(USER_TYPE) === MANAGER_ROLE ? `user/getCleanerWithManagerId/${localStorage.getItem(ADMIN_ID)}`: `user/gets/STUFF_ROLE`;
  apiObject.body = null;
  return await ApiService.callApi(apiObject);
}
//user/getAvailableCleanerWithManagerId/2023-7-13/13
//user/getAvailableCleanerWithManagerId/{allocate_date}/{areaManagerId}
export async function getAllAvailableCleaners(areaManagerId,allocateDate) {
  const apiObject = {};
  apiObject.method = 'GET';
  apiObject.authentication = true;
  apiObject.endpoint = `user/getAvailableCleanerWithManagerId/${allocateDate}/${areaManagerId}`;
  apiObject.body = null;
  return await ApiService.callApi(apiObject);
}
// area-manager logs
// log_data/getAll/type/MANAGER_STUFF/0/10
export async function areaManagerLogs(page) {
  const apiObject = {};
  apiObject.method = 'GET';
  apiObject.authentication = true;
  apiObject.endpoint = `log_data/getAll/type/MANAGER_STUFF/${page}/10`;
  apiObject.body = null;
  return await ApiService.callApi(apiObject);
}
//cleaner-site
// cleaner_site/getAllWithSiteFilter/0/10/true/false/3
// cleaner_site/getAllWithSiteFilter/{page_no}/{page_count}/{is_desc}/{is_all}/{site_id}
export async function getAllWithSiteFilter(page, siteId) {
  const apiObject = {};
  apiObject.method = 'GET';
  apiObject.authentication = true;
  apiObject.endpoint = `cleaner_site/getAllWithSiteFilter/${page}/10/true/${siteId > 0 ? false : true}/${siteId ? siteId : 0}`;
  apiObject.body = null;
  return await ApiService.callApi(apiObject);
}

//cleaner_site/save
export async function cleanerAssignSite(body) {
  const apiObject = {};
  apiObject.method = 'POST';
  apiObject.authentication = true;
  apiObject.endpoint = `cleaner_site/save`;
  apiObject.body = body;
  return await ApiService.callApi(apiObject);
}
//deleteById
export async function deleteCleanerId(id) {
  const apiObject = {};
  apiObject.method = 'DELETE';
  apiObject.authentication = true;
  apiObject.endpoint = `cleaner_site/remove/${id}`;
  apiObject.body = null;
  return await ApiService.callApi(apiObject);
}
