/**
 * Created by WebStorm.
 * User: athukorala
 * Date: 5/4/20
 * Time: 5:02 PM
 */
export const SUCCESS_MESSAGE = "SUCCESS_MAIN_DASHBOARD_MESSAGE";
export const FAILED_MESSAGE = "FAILED_MAIN_DASHBOARD_MESSAGE";
export const RESET_WARNING = 'RESET_MAIN_DASHBOARD_WARNING';

export const PENDING_STOCK_COUNT = "PENDING_STOCK_COUNT";
export const CHAT_COUNT = "CHAT_COUNT";
