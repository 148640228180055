

import ApiService from "./apiService";
import {ADMIN_ID} from "../configs/constant";

// /notification/sendChatNotification
export async function sendChatNotification(obj) {
  const apiObject = {};
  apiObject.method = 'POST';
  apiObject.authentication = true;
  apiObject.endpoint = 'notification/sendChatNotification';
  apiObject.body = obj;
  apiObject.multipart = true;
  return await ApiService.callApi(apiObject);
}
///notification/gets/receiver/1
export async function getNotifications() {
  const apiObject = {};
  apiObject.method = 'GET';
  apiObject.authentication = true;
  apiObject.endpoint = `notification/gets/receiver/${localStorage.getItem(ADMIN_ID)}`;
  apiObject.body = null;
  return await ApiService.callApi(apiObject);
}
export async function getUnSeenNotificationCount() {
  const apiObject = {};
  apiObject.method = 'GET';
  apiObject.authentication = true;
  apiObject.endpoint = `notification/getUnSeenNotificationCount/${localStorage.getItem(ADMIN_ID)}`;
  apiObject.body = null;
  apiObject.noNeed = true;
  return await ApiService.callApi(apiObject);
}
export async function updateNotificationSeenStatus() {
  const apiObject = {};
  apiObject.method = 'GET';
  apiObject.authentication = true;
  apiObject.endpoint = `notification/updateNotificationSeenStatus/${localStorage.getItem(ADMIN_ID)}`;
  apiObject.body = null;
  return await ApiService.callApi(apiObject);
}
