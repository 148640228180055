import ApiService from "./apiService";

export async function inspectionReport(filterDates, filterSiteId) {
  const apiObject = {};
  apiObject.method = 'GET';
  apiObject.authentication = true;
  apiObject.endpoint = `cleaner_allocate/report/inspect/${filterSiteId ? filterSiteId : 0}/${filterDates[0]}/${filterDates[1]}`;
  apiObject.body = null;
  return await ApiService.callApi(apiObject);
}
// inspectionReport/gets/pdf/1/2023/3/SHOPPING_CENTRE
export async function inspectionReportNew(year, month, filterSiteId, type) {
  const apiObject = {};
  apiObject.method = 'GET';
  apiObject.authentication = true;
  apiObject.endpoint = `inspectionReport/gets/pdf/${filterSiteId}/${year}/${month}/${type}`;
  apiObject.body = null;
  return await ApiService.callApi(apiObject);
}
export async function incidentReport(filterDates, filterSiteId) {
  const apiObject = {};
  apiObject.method = 'GET';
  apiObject.authentication = true;
  apiObject.endpoint = `incident_report/report/incident/${filterSiteId ? filterSiteId : 0}/${filterDates[0]}/${filterDates[1]}`;
  apiObject.body = null;
  return await ApiService.callApi(apiObject);
}
// report/attendance/{is_site}/{site_id}/{cleaner_id}/{start}/{end}
export async function attendanceReport(filterDates, filterSiteId, cleanerId) {
  const apiObject = {};
  apiObject.method = 'GET';
  apiObject.authentication = true;
  apiObject.endpoint = `attendance/report/attendance/${filterSiteId > 0}/${filterSiteId}/${cleanerId}/${filterDates[0]}/${filterDates[1]}`;
  apiObject.body = null;
  return await ApiService.callApi(apiObject);
}
export async function attendanceReportForCompany(filterDates, filterCompanyId) {
  const apiObject = {};
  apiObject.method = 'GET';
  apiObject.authentication = true;
  apiObject.endpoint = `attendance/report/getAllAttendanceRecordByCompany/${filterCompanyId}/${filterDates[0]}/${filterDates[1]}`;
  apiObject.body = null;
  return await ApiService.callApi(apiObject);
}
//attendance/report/getAllAttendanceRecord/2025-03-01/2025-03-14
export async function fullAttendanceRecords(filterDates, pageNo) {
  const apiObject = {};
  apiObject.method = 'GET';
  apiObject.authentication = true;
  apiObject.endpoint = `attendance/report/getAllAttendanceRecord/${filterDates[0]}/${filterDates[1]}/${pageNo}/10`;
  apiObject.body = null;
  return await ApiService.callApi(apiObject);
}
// send PDFs to cleaner
export async function sendPDF(obj) {
  const apiObject = {};
  apiObject.method = 'POST';
  apiObject.authentication = true;
  apiObject.endpoint = `notification/send-report`;
  apiObject.body = obj;
  apiObject.multipart = true;
  return await ApiService.callApi(apiObject);
}

// send PDFs to admin
export async function sendPDFtoAdmin(obj) {
  const apiObject = {};
  apiObject.method = 'POST';
  apiObject.authentication = true;
  apiObject.endpoint = `inspectionReport/save`;
  apiObject.body = obj;
  apiObject.multipart = true;
  return await ApiService.callApi(apiObject);
}
//addNewCleaner
export async function addNewCleaner(obj) {
  const apiObject = {};
  apiObject.method = 'POST';
  apiObject.authentication = true;
  apiObject.endpoint = `inspectionReport/addNewCleaner`;
  apiObject.body = obj;
  apiObject.multipart = true;
  return await ApiService.callApi(apiObject);
}
//adminUploadInspectionReport
export async function adminUploadInspectionReport(obj) {
  const apiObject = {};
  apiObject.method = 'POST';
  apiObject.authentication = true;
  apiObject.endpoint = `inspectionReport/adminUploadInspectionReport`;
  apiObject.body = obj;
  apiObject.multipart = true;
  return await ApiService.callApi(apiObject);
}
// inspectionReport/getAlladminUploadInspectionReportBySite/64
export async function getAlladminUploadInspectionReportBySite(obj) {
  const apiObject = {};
  apiObject.method = 'POST';
  apiObject.authentication = true;
  apiObject.endpoint = `inspectionReport/getAlladminUploadInspectionReportBySite`;
  apiObject.body = obj;
  apiObject.multipart = true;
  return await ApiService.callApi(apiObject);
}
//adminUploadCleaningDutyImage
export async function adminUploadCleaningDutyImage(obj) {
  const apiObject = {};
  apiObject.method = 'POST';
  apiObject.authentication = true;
  apiObject.endpoint = `inspectionReport/adminUploadCleaningDutyImage`;
  apiObject.body = obj;
  apiObject.multipart = true;
  return await ApiService.callApi(apiObject);
}
// inspectionReport/getAlladminUploadInspectionReportBySite/64
export async function getAllAdminUploadCleaningDutyImageBySite(obj) {
  const apiObject = {};
  apiObject.method = 'POST';
  apiObject.authentication = true;
  apiObject.endpoint = `inspectionReport/getAllAdminUploadCleaningDutyImageBySite`;
  apiObject.body = obj;
  apiObject.multipart = true;
  return await ApiService.callApi(apiObject);
}
//remove - removeAdminUploadInspectionReport
export async function adminUploadInspectionReportRemove(obj) {
  const apiObject = {};
  apiObject.method = 'POST';
  apiObject.authentication = true;
  apiObject.endpoint = `inspectionReport/removeAdminUploadInspectionReport`;
  apiObject.body = obj;
  apiObject.multipart = true;
  return await ApiService.callApi(apiObject);
}
export async function adminUploadCleaningDutyImageRemove(obj) {
  const apiObject = {};
  apiObject.method = 'POST';
  apiObject.authentication = true;
  apiObject.endpoint = `inspectionReport/removeAdminUploadCleaningDutyImage`;
  apiObject.body = obj;
  apiObject.multipart = true;
  return await ApiService.callApi(apiObject);
}
// admin manage attendance report ==========
//upload
export async function adminUploadAttendanceReport(obj) {
  const apiObject = {};
  apiObject.method = 'POST';
  apiObject.authentication = true;
  apiObject.endpoint = `attendance/uploadAttendanceReportByAdmin`;
  apiObject.body = obj;
  apiObject.multipart = true;
  return await ApiService.callApi(apiObject);
}
// remove
export async function adminUploadAttendanceReportRemove(obj) {
  const apiObject = {};
  apiObject.method = 'POST';
  apiObject.authentication = true;
  apiObject.endpoint = `attendance/removeAdminUploadAttendanceReport`;
  apiObject.body = obj;
  apiObject.multipart = true;
  return await ApiService.callApi(apiObject);
}
// get all
export async function getAlladminUploadAttendanceReportBySite(siteId, month, year) {
  const apiObject = {};
  apiObject.method = 'GET';
  apiObject.authentication = true;
  // apiObject.endpoint = `attendance/getAllAdminUploadAttendanceReportBySite`;
  apiObject.endpoint = `attendance/getAllAdminUploadAttendanceReportBySiteAndYearMonth/${siteId}/${year}/${month}`
  apiObject.body = null;
  return await ApiService.callApi(apiObject);
}
