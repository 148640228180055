export const ACCESS_TOKEN = "alpha-admin-access";
export const REFRESH_TOKEN = "alpha-admin-refresh";
export const ADMIN_ID = "alpha-admin-id";
export const ADMIN_FCM_TOKEN = "alpha-admin-fcm-token";
export const USER_TYPE = "alpha-user-type";
export const USER_NAME = "alpha-user-name";
export const USER_EMAIL = "alpha-user-email";
export const USER_IMAGE = "alpha-user-image";
export const SITE_ID = "alpha-site-id";
export const ROLE_ID = "alpha-role-id";

export const BASE_ROUTE_PATH = "";
// export const BASE_ROUTE_PATH = "/alpha-web";

export const ROUTE_DASHBOARD = "/";
export const ROUTE_LOGIN = "/login";

export const ROUTE_CALENDAR = "/calendar-preview";
export const ROUTE_INCIDENT_REQ = "/incident-request";
export const ROUTE_INCIDENT_REQ_MANAGE = `${ROUTE_INCIDENT_REQ}/manage`;
export const ROUTE_INCIDENT_REQ_MANAGE_CLIENT = `${ROUTE_INCIDENT_REQ}/manager-log`;
export const ROUTE_INCIDENT_REQ_LOGS = `${ROUTE_INCIDENT_REQ}/logs`;

export const ROUTE_SERVICE_REQ = "/service-request";
export const ROUTE_SERVICE_REQ_MANAGE = `${ROUTE_SERVICE_REQ}/manage`;
export const ROUTE_SERVICE_REQ_MANAGE_CLIENT = `${ROUTE_SERVICE_REQ}s`;
export const ROUTE_SERVICE_REQ_MANAGE_MANAGER = `${ROUTE_SERVICE_REQ}/manager-requests`;
export const ROUTE_SERVICE_REQ_LOGS = `${ROUTE_SERVICE_REQ}/logs`;
export const ROUTE_CREATED_SERVICE_REQS = `${ROUTE_SERVICE_REQ}/created-requests`;
export const ROUTE_CREATED_CLIENT_SERVICE_REQS = `${ROUTE_SERVICE_REQ}/client-service-requests`;
export const ROUTE_MANAGER_REQUEST_COMPLETION = `${ROUTE_SERVICE_REQ}/manager-service-request-completion`;

export const ROUTE_SITE_SUPERVISION = "/site-supervision";
export const ROUTE_MANAGE_EMPLOYEE = "/manage-employee";
export const ROUTE_CURRENT_STAFF = "/current-staff";
export const ROUTE_CURRENT_STAFF_MANAGER = "/my-staff";
export const ROUTE_AREA_MANAGER_REQUEST = "/area-manager-requests";
export const ROUTE_AREA_MANAGER_REQUEST_LOGS = "/area-manager-requests/logs";

export const ROUTE_COMPANIES = "/manage-company";
export const ROUTE_SITES = "/manage-site";
export const ROUTE_ALPHA_SITES = "/alpha-site";
export const ROUTE_ALPHA_MY_SITES = "/my-sites";
export const ROUTE_ALPHA_SITES_ALLOCATE = "/alpha-site/allocate";
export const ROUTE_STAFF = "/manage-staff";
export const ROUTE_CLIENTS = "/manage-clients";
export const ROUTE_REPORT = "/reports";
export const ROUTE_CHAT = "/chat";
export const ROUTE_SETTINGS = "/settings";

export const ROUTE_SHIFT_SCHEDULE = "/shift-schedule";
export const ROUTE_SHIFT_SCHEDULE_MANAGER = "/shift-schedule/manager";
export const ROUTE_SHIFT_SCHEDULE_ADMIN = "/shift-schedule/admin";
export const ROUTE_SHIFT_SCHEDULE_ADMIN_APPROVAL = "/shift-schedule/approval";

export const ADDITIONAL_INFO = "/additional-info";
export const AREA_OF_IMPR = "/area-of-improvements";

export const RETAIL_INSP = "/retail-inspection";
export const OFFICE_GENERAL_INSP = "/office-general-inspection";
export const HIGH_LEVEL_CLEANING_DIGITAL_INSP = "/high-level-cleaning-digital-inspection";
export const CHILD_CARE_INSP = "/child-care-inspection";
export const SHOPPING_INSP = "/shopping-inspection";

export const ADMIN_MANAGE_INSPECTION_REPORT = "/admin-manage-inspection-report";
export const ADMIN_MANAGE_ATTENDANCE_REPORT = "/admin-manage-attendance-report";
export const ADMIN_MANAGE_CLEANING_DUTY_PHOTOS = "/admin-manage-cleaning-duty-photos";
export const ADMIN_REPORT_CONSUMABLE_REGISTRY = "/admin-report-consumable-registry";

export const CLIENT_INSPECTION_REPORT = "/client-inspection-report";
export const CLIENT_ATTENDANCE_REPORT = "/client-attendance-report";
export const CLIENT_CLEANING_DUTY_PHOTOS = "/client-cleaning-duty-photos";

export const INSPECTION_REPORT = "/reports/inspection-report";
export const HIGH_LEVEL_CLEANING_DIGITAL_INSPECTION_REPORT = "/reports/high-level-cleaning-digital-inspection-report";
export const ATTENDANCE_REPORT = "/reports/attendance-report";
export const FULL_ATTENDANCE_REPORT = "/reports/full-attendance-record";
export const INCIDENT_REPORT = "/reports/incident-report";

export const REAL_TIME_DATA = "/real-time-data";

export const ITEM_MANAGEMENT = "/stock/item-management";
export const ALLOCATE_QUANTITY = "/stock/allocate-quantity";
export const SUPERVISOR_REQUEST = "/stock/supervisor-request";

export const WAREHOUSE_STOCK = "/stock/warehouse-stock";
export const DELIVERY_LOG = "/stock/delivery-log";
export const CONSUMABLE_REGISTRY = "/stock/consumable-registry";
//-----------------

export const UPCOMING = "Upcoming";
export const PENDING= "Pending";
export const ONGOING= "Ongoing";
export const APPROVED = "Approved";
export const COMPLETED = "Completed";
export const CANCELED = "Canceled";
export const REJECTED = "Rejected";

export const INCIDENT_REQ = "INCIDENT";
export const SERVICE_REQ = "SERVICE";

export const ADMIN_ROLE = "ADMIN_ROLE"; // admin
export const SUPERVISOR_ROLE = "SUPERVISOR_ROLE"; // manager > client
export const MANAGER_ROLE = "MANAGER_ROLE"; // client > manager
// export const CLIENT_ROLE = "CLIENT_ROLE";

export const MANAGER_ROLE_REPLACE = "CLIENT_ROLE";
export const SUPERVISOR_ROLE_REPLACE = "MANAGER_ROLE";
export const STUFF_ROLE = "STUFF_ROLE";

export const PENDING_STATE = "PENDING";
export const CANCELED_STATE = "CANCELED";
export const REJECTED_STATE = "REJECTED";
export const COMPLETED_STATE = "COMPLETED";
export const INACTIVE_STATE = "INACTIVE";
export const ACTIVE_STATE = "ACTIVE";

// https://ifconfig.co/ip
export const FIREBASE_VAPID_KEY = "BPy8x1BSHKjPIfDBpFsFjCcILZFPpbmI_uR5V4ABibMiBb_n7XPia9UQDXutjRIP2aQaOlNlMqS_Ku1qRCH0iz0";
