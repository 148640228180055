import React from "react"
import * as Icon from "react-feather"
import * as constant from "./constant"
import {
  ADMIN_MANAGE_ATTENDANCE_REPORT,
  ADMIN_REPORT_CONSUMABLE_REGISTRY,
  ADMIN_ROLE,
  CHILD_CARE_INSP,
  CLIENT_ATTENDANCE_REPORT,
  CLIENT_CLEANING_DUTY_PHOTOS,
  CLIENT_INSPECTION_REPORT,
  CONSUMABLE_REGISTRY,
  HIGH_LEVEL_CLEANING_DIGITAL_INSP,
  HIGH_LEVEL_CLEANING_DIGITAL_INSPECTION_REPORT,
  MANAGER_ROLE,
  OFFICE_GENERAL_INSP,
  ROUTE_ALPHA_MY_SITES,
  ROUTE_SERVICE_REQ_MANAGE_CLIENT,
  SUPERVISOR_ROLE,
} from "./constant";

const navigationConfig = [
  {
    id: "1",
    title: "Dashboard",
    type: "item",
    icon: <Icon.Monitor size={20}/>,
    permissions: [ADMIN_ROLE, MANAGER_ROLE, SUPERVISOR_ROLE],
    navLink: constant.BASE_ROUTE_PATH + constant.ROUTE_DASHBOARD
  },
  {
    id: "2",
    title: "Calendar",
    type: "item",
    icon: <Icon.Calendar size={20}/>,
    permissions: [ADMIN_ROLE, MANAGER_ROLE], //SUPERVISOR_ROLE
    navLink: constant.BASE_ROUTE_PATH + constant.ROUTE_CALENDAR
  },
  {
    id: "59",
    title: "Real-Time Data",
    type: "item",
    icon: <Icon.Clock size={20}/>,
    permissions: [ADMIN_ROLE, MANAGER_ROLE],
    navLink: constant.BASE_ROUTE_PATH + constant.REAL_TIME_DATA,
  },

  {
    id: "5",
    title: "Alpha Staff",
    // type: "item",
    icon: <Icon.Users size={20}/>,
    permissions: [ADMIN_ROLE, MANAGER_ROLE], // MANAGER_ROLE - NOT VISIBLE - recheck
    navLink: constant.BASE_ROUTE_PATH + constant.ROUTE_MANAGE_EMPLOYEE,
    type: "collapse",
    children: [
      {
        id: "68",
        title: "Add Staff",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [ADMIN_ROLE, MANAGER_ROLE], // supervisor recheck
        navLink: constant.BASE_ROUTE_PATH+constant.ROUTE_MANAGE_EMPLOYEE,
      },
      {
        id: "69",
        title: "Current Staff",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [ADMIN_ROLE], //  supervisor recheck
        navLink: constant.BASE_ROUTE_PATH+constant.ROUTE_CURRENT_STAFF,
      },
      {
        id: "69",
        title: "My Staff",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [MANAGER_ROLE], //  supervisor recheck
        navLink: constant.BASE_ROUTE_PATH+constant.ROUTE_CURRENT_STAFF_MANAGER,
      },
      {
        id: "70",
        title: "Area Manager Requests",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [ADMIN_ROLE],
        navLink: constant.BASE_ROUTE_PATH+constant.ROUTE_AREA_MANAGER_REQUEST,
      },
      {
        id: "72",
        title: "Logs",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [MANAGER_ROLE], //  supervisor recheck
        navLink: constant.BASE_ROUTE_PATH+constant.ROUTE_AREA_MANAGER_REQUEST_LOGS,
      }
      ]
  },
  {
    id: "8",
    title: "Alpha Management",
    type: "item", // "collapse",
    icon: <Icon.Star size={20}/>,
    permissions: [ADMIN_ROLE], // ADMIN ONLY
    navLink: constant.BASE_ROUTE_PATH + constant.ROUTE_STAFF,
  },
  {
    id: "81",
    title: "My Sites",
    type: "item",
    icon: <Icon.Home size={20}/>,
    permissions: [SUPERVISOR_ROLE],
    navLink: constant.BASE_ROUTE_PATH+constant.ROUTE_ALPHA_MY_SITES,
  },
  {
    id: "6",
    title: "Company Management",
    type: "collapse",
    icon: <Icon.Home size={20}/>,
    permissions: [ADMIN_ROLE, MANAGER_ROLE], //SUPERVISOR_ROLE
    children: [
      {
        id: "61",
        title: "Client",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [ADMIN_ROLE],
        navLink: constant.BASE_ROUTE_PATH+constant.ROUTE_CLIENTS,
      },
      {
        id: "26",
        title: "Add Company",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [ADMIN_ROLE],
        navLink: constant.BASE_ROUTE_PATH+constant.ROUTE_COMPANIES,
      },
      {
        id: "28",
        title: "Add Site",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [ADMIN_ROLE], //MANAGER_ROLE
        navLink: constant.BASE_ROUTE_PATH+constant.ROUTE_SITES,
      },
      {
        id: "71",
        title: "Alpha Sites",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [ADMIN_ROLE],
        navLink: constant.BASE_ROUTE_PATH+constant.ROUTE_ALPHA_SITES,
      },
      {
        id: "79",
        title: "Cleaners Allocate",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [MANAGER_ROLE, ADMIN_ROLE], // issue
        navLink: constant.BASE_ROUTE_PATH+constant.ROUTE_ALPHA_SITES_ALLOCATE,
      },
    ]
  },
  {
    id: "64",
    title: "Shift Schedule",
    type: "collapse",
    icon: <Icon.Clock size={20}/>,
    permissions: [ADMIN_ROLE, MANAGER_ROLE],
    // navLink: constant.BASE_ROUTE_PATH + constant.ROUTE_SHIFT_SCHEDULE,
    children: [
      {
        id: "76",
        title: "Manage Schedules",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [MANAGER_ROLE],
        navLink: constant.BASE_ROUTE_PATH + constant.ROUTE_SHIFT_SCHEDULE_MANAGER,
      },
      {
        id: "78",
        title: "View Schedules",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [ADMIN_ROLE],
        navLink: constant.BASE_ROUTE_PATH + constant.ROUTE_SHIFT_SCHEDULE_ADMIN,
      },
      {
        id: "77",
        title: "Admin Approval",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [ADMIN_ROLE],
        navLink: constant.BASE_ROUTE_PATH + constant.ROUTE_SHIFT_SCHEDULE_ADMIN_APPROVAL,
      },
    ]
  },
  {
    id: "82",
    title: "Service Requests",
    type: "item",
    icon: <Icon.Coffee size={20}/>,
    permissions: [SUPERVISOR_ROLE],
    navLink: constant.BASE_ROUTE_PATH+constant.ROUTE_SERVICE_REQ_MANAGE_CLIENT,
  },
  {
    id: "4",
    title: "Service Report", //Service Requests
    type: "collapse",
    icon: <Icon.Coffee size={20}/>,
    permissions: [ADMIN_ROLE, MANAGER_ROLE ], //SUPERVISOR_ROLE
    children: [
      // {
      //   id: "51",
      //   title: "Service Requests", //Work Order
      //   type: "item",
      //   icon: <Icon.Circle size={12} />,
      //   permissions: [ADMIN_ROLE, SUPERVISOR_ROLE],
      //   navLink: constant.BASE_ROUTE_PATH + constant.ROUTE_SITE_SUPERVISION,
      // },
      {
        id: "24",
        title: "Service Requests",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [ADMIN_ROLE, SUPERVISOR_ROLE],
        navLink: constant.BASE_ROUTE_PATH+constant.ROUTE_SERVICE_REQ_MANAGE,
      },
      {
        id: "73",
        title: "Created Service Request",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [ADMIN_ROLE],
        navLink: constant.BASE_ROUTE_PATH+constant.ROUTE_CREATED_SERVICE_REQS,
      },
      {
        id: "74",
        title: "Client Service Request",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [ADMIN_ROLE],
        navLink: constant.BASE_ROUTE_PATH+constant.ROUTE_CREATED_CLIENT_SERVICE_REQS,
      },
      {
        id: "75",
        title: "Review Service Requests", //Manager Completion Requests
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [ADMIN_ROLE],
        navLink: constant.BASE_ROUTE_PATH+constant.ROUTE_MANAGER_REQUEST_COMPLETION,
      },
      {
        id: "80",
        title: "Manager Requests", //Manager Service Requests Completion
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [MANAGER_ROLE],
        navLink: constant.BASE_ROUTE_PATH+constant.ROUTE_SERVICE_REQ_MANAGE_MANAGER,
      },
      {
        id: "25",
        title: "Logs",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [ADMIN_ROLE],
        navLink: constant.BASE_ROUTE_PATH+constant.ROUTE_SERVICE_REQ_LOGS,
      },
    //
    ]
  },
  {
    id: "85",
    title: "Inspection Reports",
    type: "item",
    icon: <Icon.Camera size={20} />,
    permissions: [SUPERVISOR_ROLE],
    navLink: constant.BASE_ROUTE_PATH+constant.CLIENT_INSPECTION_REPORT,
  },
  {
    id: "92",
    title: "Attendance Reports",
    type: "item",
    icon: <Icon.Camera size={20} />,
    permissions: [SUPERVISOR_ROLE],
    navLink: constant.BASE_ROUTE_PATH+constant.CLIENT_ATTENDANCE_REPORT,
  },
  {
    id: "86",
    title: "Cleaning Duty Photos",
    type: "item",
    icon: <Icon.Camera size={20} />,
    permissions: [SUPERVISOR_ROLE],
    navLink: constant.BASE_ROUTE_PATH+constant.CLIENT_CLEANING_DUTY_PHOTOS,
  },
  {
    id: "7",
    title: "Site Inspection",
    type: "collapse",
    icon: <Icon.Camera size={20}/>,
    permissions: [ADMIN_ROLE, MANAGER_ROLE], // MANAGER_ROLE - NOT VISIBLE
    children: [
      {
        id: "65",
        title: "Retail Inspection",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [ADMIN_ROLE, MANAGER_ROLE],
        navLink: constant.BASE_ROUTE_PATH+constant.RETAIL_INSP,
      },
      {
        id: "66",
        title: "Shopping Centres Inspections",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [ADMIN_ROLE, MANAGER_ROLE],
        navLink: constant.BASE_ROUTE_PATH+constant.SHOPPING_INSP,
      },
      {
        id: "87",
        title: "Child Care Inspection",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [ADMIN_ROLE, MANAGER_ROLE],
        navLink: constant.BASE_ROUTE_PATH+constant.CHILD_CARE_INSP,
      },
      {
        id: "88",
        title: "Office/General Inspection",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [ADMIN_ROLE, MANAGER_ROLE],
        navLink: constant.BASE_ROUTE_PATH+constant.OFFICE_GENERAL_INSP,
      },
      {
        id: "90",
        title: "High-Level Cleaning Digital Inspection",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [ADMIN_ROLE],
        navLink: constant.BASE_ROUTE_PATH+constant.HIGH_LEVEL_CLEANING_DIGITAL_INSP,
      },
      {
        id: "83",
        title: "Admin Manage Inspection Report",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [ADMIN_ROLE],
        navLink: constant.BASE_ROUTE_PATH+constant.ADMIN_MANAGE_INSPECTION_REPORT,
      },
      {
        id: "84",
        title: "Admin Manage Cleaning Duty Photos",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [ADMIN_ROLE],
        navLink: constant.BASE_ROUTE_PATH+constant.ADMIN_MANAGE_CLEANING_DUTY_PHOTOS,
      },
    ]
  },
  // {
  //   id: "83",
  //   title: "Incident Requests",
  //   type: "item",
  //   icon: <Icon.Coffee size={20}/>,
  //   permissions: [MANAGER_ROLE],
  //   navLink: constant.BASE_ROUTE_PATH+constant.ROUTE_INCIDENT_REQ_MANAGE_CLIENT,
  // },
  {
    id: "3",
    title: "Incident Requests",
    type: "collapse",
    icon: <Icon.Coffee size={20}/>,
    permissions: [ADMIN_ROLE], //SUPERVISOR_ROLE
    children: [
      {
        id: "22",
        title: "Manage",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [ADMIN_ROLE, MANAGER_ROLE],
        navLink: constant.BASE_ROUTE_PATH+constant.ROUTE_INCIDENT_REQ_MANAGE,
      },
      // {
      //   id: "67",
      //   title: "Logs",
      //   type: "item",
      //   icon: <Icon.Circle size={12} />,
      //   permissions: [MANAGER_ROLE], // MANAGER ONLY VIEW
      //   navLink: constant.BASE_ROUTE_PATH+constant.ROUTE_INCIDENT_REQ_MANAGE_CLIENT,
      // },
      {
        id: "23",
        title: "Logs",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [ADMIN_ROLE],
        navLink: constant.BASE_ROUTE_PATH+constant.ROUTE_INCIDENT_REQ_LOGS,
      }
    ]
  },
  {
    id: "56",
    title: "Stock Management",
    type: "collapse",
    icon: <Icon.ShoppingBag size={20}/>,
    permissions: [ADMIN_ROLE, MANAGER_ROLE], // NOT VISIBLE TO CLIENT
    children: [
      {
        id: "62",
        title:"Warehouse Stock",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [ADMIN_ROLE],
        navLink: constant.BASE_ROUTE_PATH+constant.WAREHOUSE_STOCK,
      },
      {
        id: "57",
        title: "Warehouse Par Level", // Consumable
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [ADMIN_ROLE],
        navLink: constant.BASE_ROUTE_PATH+constant.ITEM_MANAGEMENT,
      },
      {
        id: "58",
        title: "Cleaner requests", // Allocated quantity
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [MANAGER_ROLE],
        navLink: constant.BASE_ROUTE_PATH+constant.ALLOCATE_QUANTITY,
      },
      {
        id: "60",
        title: "Manager Requests",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [ADMIN_ROLE, MANAGER_ROLE],
        navLink: constant.BASE_ROUTE_PATH+constant.SUPERVISOR_REQUEST,
      },
      {
        id: "63",
        title:"Consumables Delivery log",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [ADMIN_ROLE],
        navLink: constant.BASE_ROUTE_PATH+constant.DELIVERY_LOG,
      },
    //  Consumable Registry
      {
        id: "93",
        title:"Consumable Registry",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [ADMIN_ROLE],
        navLink: constant.BASE_ROUTE_PATH+constant.CONSUMABLE_REGISTRY,
      },
    ]
  },
  {
    id: "31",
    title: "Chat",
    type: "item",
    icon: <Icon.MessageSquare size={20}/>,
    permissions: [MANAGER_ROLE, ADMIN_ROLE], // SUPERVISOR ONLY -> SUPERVISOR_ROLE
    navLink: constant.BASE_ROUTE_PATH + constant.ROUTE_CHAT,
  },
  {
    id: "9",
    title: "Reports",
    type: "collapse",
    icon: <Icon.PieChart size={20}/>,
    permissions: [ADMIN_ROLE, MANAGER_ROLE], //SUPERVISOR_ROLE
    children: [
      {
        id: "53",
        title: "Inspection Report",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [ADMIN_ROLE, MANAGER_ROLE, SUPERVISOR_ROLE],
        navLink: constant.BASE_ROUTE_PATH+constant.INSPECTION_REPORT,
      },
      {
        id: "94",
        title: "High-Level Cleaning Digital Report",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [ADMIN_ROLE],
        navLink: constant.BASE_ROUTE_PATH+constant.HIGH_LEVEL_CLEANING_DIGITAL_INSPECTION_REPORT,
      },
      {
        id: "54",
        title: "Attendance Report",
        type: "item",
        icon: <Icon.Circle size={12} />,
        // permissions: [ADMIN_ROLE, MANAGER_ROLE, SUPERVISOR_ROLE],
        permissions: [ADMIN_ROLE, SUPERVISOR_ROLE],
        navLink: constant.BASE_ROUTE_PATH+constant.ATTENDANCE_REPORT,
      },
      // {
      //   id: "96",
      //   title: "Full Attendance Records",
      //   type: "item",
      //   icon: <Icon.Circle size={12} />,
      //   permissions: [ADMIN_ROLE],
      //   navLink: constant.BASE_ROUTE_PATH+constant.FULL_ATTENDANCE_REPORT,
      // },
      {
        id: "55",
        title: "Incident Report",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [ADMIN_ROLE, MANAGER_ROLE, SUPERVISOR_ROLE],
        navLink: constant.BASE_ROUTE_PATH+constant.INCIDENT_REPORT,
      },
      {
        id: "91",
        title: "Admin Manage Attendance Report",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [ADMIN_ROLE],
        navLink: constant.BASE_ROUTE_PATH+constant.ADMIN_MANAGE_ATTENDANCE_REPORT,
      },
    //  Consumable Registry Report
      {
        id: "95",
        title: "Consumable Registry Report",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [ADMIN_ROLE],
        navLink: constant.BASE_ROUTE_PATH+constant.ADMIN_REPORT_CONSUMABLE_REGISTRY,
      },
    ]
  },
  {
    id: "40",
    title: "Settings",
    type: "item",
    icon: <Icon.Settings size={20}/>,
    permissions: [ADMIN_ROLE, MANAGER_ROLE, SUPERVISOR_ROLE], // SUPERVISOR ONLY
    navLink: constant.BASE_ROUTE_PATH + constant.ROUTE_SETTINGS,
  }
];

export default navigationConfig
//96
