import * as actionTypes from "./actionType";
import {spinnerHandler} from "../spinner/action";
// import Service from "../../../services/dashboard";
import {getPendingStockCounts} from "../../../services/stock";
import {PENDING_STOCK_COUNT} from "./actionType";

/**
 * Created by WebStorm.
 * User: athukorala
 * Date: 5/4/20
 * Time: 4:59 PM
 */
export const successMessageHandler = (data) => {
  return {
    type: actionTypes.SUCCESS_MESSAGE,
    value: data
  }
};

export const failedMessageHandler = (data) => {
  return {
    type: actionTypes.FAILED_MESSAGE,
    value: data
  }
};

export const resetWarningHandler = () => {
  return {
    type: actionTypes.RESET_WARNING,
    value: null
  }
};
// export function mainDashboardHandler(data) {
//   return dispatch => {
//     dispatch(spinnerHandler(true));
//     Service.mainDashboardHandler(data)
//       .then((response) => {
//         if (response.success) {
//           dispatch(successMessageHandler(response));
//         } else {
//           dispatch(failedMessageHandler(response));
//         }
//       })
//       .catch((e) => {
//         dispatch(failedMessageHandler({
//           status: 2,
//           message: "Communication Failure",
//         }));
//       })
//       .finally(fin=>{
//         dispatch(spinnerHandler(false));
//       })
//   };
// }


export function getPendingStockCount(data) {
  return dispatch => {
    // dispatch(spinnerHandler(true));
    getPendingStockCounts(data)
      .then((response) => {
        if (response.code === "200") {
          dispatch(pendingStock(response.result));
        } else {
          dispatch(pendingStock(0));
        }
      })
  };
}
export const pendingStock = (data) => {
  return {
    type: actionTypes.PENDING_STOCK_COUNT,
    value: data
  }
};
export const chatCountHandler = (data) => {
  return {
    type: actionTypes.CHAT_COUNT,
    value: data
  }
};
function getRandom(min, max) {
  const floatRandom = Math.random()

  const difference = max - min

  // random between 0 and the difference
  const random = Math.round(difference * floatRandom)

  const randomWithinRange = random + min

  return randomWithinRange
}
