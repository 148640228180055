import {
  ADMIN_ROLE, APPROVED,
  CANCELED,
  COMPLETED,
  MANAGER_ROLE, MANAGER_ROLE_REPLACE,
  ONGOING,
  PENDING, REJECTED, STUFF_ROLE,
  SUPERVISOR_ROLE, SUPERVISOR_ROLE_REPLACE,
  UPCOMING
} from "../configs/constant";

export const mealTypes = [
  {value: "BREAKFAST", text: "Breakfast"},
  {value: "MORNING_SNACK", text: "Morning Snack"},
  {value: "LUNCH", text: "Lunch"},
  {value: "AFTERNOON_SNACK", text: "Afternoon Snack"},
  {value: "DINNER", text: "Dinner"},
];
export const stateTypes = [
  {value: "VIC", text: "VIC"},
  {value: "SA", text: "SA"},
  {value: "WA", text: "WA"},
  {value: "QLD", text: "QLD"},
  {value: "NT", text: "NT"},
  {value: "ACT", text: "ACT"},
  {value: "TAS", text: "TAS"},
  {value: "NSW", text: "NSW"},

];
export const ratingTypes = [
  {value: "1", text: "1"},
  {value: "2", text: "2"},
  {value: "3", text: "3"},
  {value: "4", text: "4"},
  {value: "5", text: "5"},
];
export const consumableDropdown = [
  {value: "1/4", text: "1/4"},
  {value: "2/4", text: "2/4"},
  {value: "3/4", text: "3/4"},
  {value: "4/4", text: "4/4"},
  {value: "0", text: "0"},
];
export const consumableDropdown2 = [
  {value: "Poor", text: "Poor"},
  {value: "Satisfactory", text: "Satisfactory"}
];
export const highLevelAdHoc = [
  {value: "N/A", text: "N/A"},
  {value: "Yes", text: "Yes"},
  {value: "No", text: "No"}
];
export const consumableDropdown3 = [
  {value: "Not Required", text: "Not Required"},
  {value: "Delivered", text: "Delivered"},
  {value: "To Be Ordered", text: "To Be Ordered"}
];
export const monthsOptions = [
  {value: 1, text: "Jan"},
  {value: 2, text: "Feb"},
  {value: 3, text: "Mar"},
  {value: 4, text: "Apr"},
  {value: 5, text: "May"},
  {value: 6, text: "Jun"},
  {value: 7, text: "Jul"},
  {value: 8, text: "Aug"},
  {value: 9, text: "Sep"},
  {value: 10, text: "Oct"},
  {value: 11, text: "Nov"},
  {value: 12, text: "Dec"},
];
export const daysOptions = [
  {value: "MONDAY", text: "Mon"},
  {value: "TUESDAY", text: "Tue"},
  {value: "WEDNESDAY", text: "Wed"},
  {value: "THURSDAY", text: "Thu"},
  {value: "FRIDAY", text: "Fri"},
  {value: "SATURDAY", text: "Sat"},
  {value: "SUNDAY", text: "Sun"},
];
export const yearOptions = [
    {"text":new Date().getFullYear(), "value":new Date().getFullYear()},
    {"text":new Date(new Date().setFullYear(new Date().getFullYear() + 1)).getFullYear(), "value":new Date(new Date().setFullYear(new Date().getFullYear() + 1)).getFullYear()},
    {"text":new Date(new Date().setFullYear(new Date().getFullYear() + 2)).getFullYear(), "value":new Date(new Date().setFullYear(new Date().getFullYear() + 2)).getFullYear()}
  ];
export const genderOptions = [
  {value: "MALE", text: "Male"},
  {value: "FEMALE", text: "Female"},
];
export const categoryOptions = [
  {value: "OH&S", text: "OH&S"},
  {value: "Environmental", text: "Environmental"},
  {value: "Operational", text: "Operational"}
];
export const categorySerOptions = [
  {value: "Cleaning", text: "Cleaning"},
  {value: "Services", text: "Services"}
];
export const measurementOptions = [
  {text: "pieces", value: "pieces"},
  {text: "L (liter)", value: "L"},
  {text: "ml (milliliter)", value: "ml"},
  {text: "Kg (kilogram)", value: "Kg"},
  {text: "g (gram)", value: "g"},
  {text: "M (meter)", value: "M"},
  {text: "Cm (centimeter)", value: "Cm"},
];
export const typesOfIssueOptions = [
  {value: "Incident", text: "Incident"},
  {value: "Personal_Injury", text: "Personal Injury"},
  {value: "Near_miss", text: "Near miss"},
  {value: "Accident", text: "Accident"},
  {value: "Fire", text: "Fire"},
  {value: "Non-conforming_service", text: "Non-conforming service"},
  {value: "Customer_complaint", text: "Customer complaint"},
  {value: "Contractor_Supplier_related", text: "Contractor/Supplier related"},
  {value: "Equipment_Failure", text: "Equipment Failure"},
  {value: "Chemical_Spill", text: "Chemical Spill"},
  {value: "Heavy_equipment", text: "Heavy equipment"},
  {value: "Light_vehicle", text: "Light vehicle (Car)"},
  {value: "Property_Damage", text: "Property Damage"},
  {value: "Other", text: "Other"}
];
export const injuryTypeOptions = [
  {value: "1st_Aid", text: "1st Aid"},
  {value: "MTI", text: "MTI"},
  {value: "LTI", text: "LTI"},
  {value: "Fatality", text: "Fatality"},
];

export const userTypeOptions = [
  {value: ADMIN_ROLE, text: "Admin"},
  {value: MANAGER_ROLE, text: "Area Manager"},
  {value: SUPERVISOR_ROLE, text: "Client"}
];
// export const userTypeOptionsConverted = [
//   {value: ADMIN_ROLE, text: ADMIN_ROLE},
//   {value: SUPERVISOR_ROLE, text: SUPERVISOR_ROLE_REPLACE},
//   {value: MANAGER_ROLE, text: MANAGER_ROLE_REPLACE}
// ];
export const userTypeOptionsConverted = [
  {value: ADMIN_ROLE, text: ADMIN_ROLE},
  {value: MANAGER_ROLE, text: SUPERVISOR_ROLE_REPLACE},
  {value: SUPERVISOR_ROLE, text: MANAGER_ROLE_REPLACE}
];
export const allUserTypeOptions = [
  {value: ADMIN_ROLE, text: ADMIN_ROLE},
  {value: MANAGER_ROLE, text: SUPERVISOR_ROLE_REPLACE},
  {value: SUPERVISOR_ROLE, text: MANAGER_ROLE_REPLACE},
  {value: STUFF_ROLE, text: STUFF_ROLE}
];
export const userRoleOptions = [
  {value: "1", text: ADMIN_ROLE},
  {value: "2", text: MANAGER_ROLE_REPLACE},
  {value: "3", text: SUPERVISOR_ROLE_REPLACE}
];

export const userRoleOptionsMin = [
  {value: "1", text: ADMIN_ROLE},
  // {value: "2", text: MANAGER_ROLE_REPLACE},
  {value: "2", text: SUPERVISOR_ROLE_REPLACE}
];

export const cleanerTypes = [
  {value: "CASUAL", text: "Casual"},
  {value: "PERMANENT", text: "Permanent"},
  {value: "PART_TIME", text: "Part-time"}
];

export const cleanerCategoryTypes = [
  {value: "General Maintenance", text: "General Maintenance"},
  {value: "Electrical", text: "Electrical"},
  {value: "Plumbing", text: "Plumbing"},
  {value: "Painting", text: "Painting"},
  {value: "Graﬃti Removing", text: "Graﬃti Removing"},
  {value: "High Pressure", text: "High Pressure"},
  {value: "Ad hoc", text: "Ad hoc"},
  {value: "Other", text: "Other"}
];
export const serviceCategoryCleanerTypes = [
  {value: "Extra staff/Addhoc", text: "Extra staff/Addhoc"},
  {value: "Steam cleaning", text: "Steam cleaning"},
  {value: "Pressure washing", text: "Pressure washing"},
  {value: "Window Cleaning", text: "Window Cleaning"},
  {value: "Graffiti Removal", text: "Graffiti Removal"},
  {value: "Other", text: "Other"}
];
export const serviceCategoryServiceTypes = [
  {value: "Electrical", text: "Electrical"},
  {value: "Plumbing", text: "Plumbing"},
  {value: "General Maintenance", text: "General Maintenance"},
  {value: "Air Conditions", text: "Air Conditions"},
  {value: "Other", text: "Other"}
];

export const userStatusOptions = [
  {value: 0, text: "Pending"},
  {value: 1, text: "Complete"}
];

export const testCompanies = [
  {value: 0, text: "Carlisle Homes West"},
  {value: 1, text: "Chemist Warehouse TAS"}
];
export const testSites = [
  {value: 0, text: "Springhill Shopping Centre"},
  {value: 1, text: "Ravenhall Colliers"}
];
export const roleOptions = [
  {value: PENDING, text: PENDING},
  {value: UPCOMING, text: UPCOMING},
  {value: ONGOING, text: ONGOING},
  {value: COMPLETED, text: COMPLETED},
  {value: CANCELED, text: CANCELED}
];
export const serviceRoleOptions = [
  {value: PENDING, text: PENDING},
  // {value: UPCOMING, text: UPCOMING},
  // {value: ONGOING, text: ONGOING},
  {value: COMPLETED, text: COMPLETED},
  {value: REJECTED, text: REJECTED}
];
export const serviceReqStatus = [
  {value: "PENDING", text: PENDING},
  {value: "APPROVE", text: APPROVED},
  {value: "REJECT", text: REJECTED}
];
export const serviceTypeOptions = [
  {value: "Cleaning", text: "Cleaning"},
  {value: "Service", text: "Service"}
];
export const issueCategoryOptions = [
  {value: "Operational", text: "Operational"},
  {value: "OHS", text: "OHS"},
  {value: "Environment", text: "Environment"}
];
export const issueType1Options = [
  {key: 1, value: "Property Damage", text: "Property Damage"},
  {key: 2, value: "Contractor / Supplier related", text: "Contractor / Supplier related"},
  {key: 3, value: "Equipment failure", text: "Equipment failure"},
  {key: 4, value: "Non- Confirming services", text: "Non- Confirming services"},
  {key: 5, value: "Heavy Equipment", text: "Heavy Equipment"},
  // {key: 6, value: "Equipment failure", text: "Equipment failure"}
];
export const issueType2Options = [
  {key: 7, value: "Personal injury", text: "Personal injury"},
  {key: 8, value: "Accident", text: "Accident"},
  {key: 9, value: "Syringe", text: "Syringe"},
  {key: 10, value: "Blood", text: "Blood"},
  {key: 11, value: "Near Miss", text: "Near Miss"}
];
export const issueType3Options = [
  {key: 12, value: "Chemical Spill", text: "Chemical Spill"}
];
export const priorityOptions = [
  {value: "LOW", text: "Low"},
  {value: "MEDIUM", text: "Medium"},
  {value: "HIGH", text: "High"},
  {value: "CRITICAL", text: "Critical"}
];
export const authorityNameOptions = [
  {value: "Environmental Protection Authority", text: "Environmental Protection Authority"},
  {value: "Work safe", text: "Work safe"},
];
export const insurerOptions = [
  {value: "Property", text: "Property"},
  {value: "Work cover", text: "Work cover"},
];
