import ApiService from "./apiService";
import {ADMIN_ID, ADMIN_ROLE, MANAGER_ROLE, USER_TYPE} from "../configs/constant";

// stock item save /update
export async function manage(body, id) {
  const apiObject = {};
  apiObject.method = id > 0 ? 'PUT':'POST';
  apiObject.authentication = true;
  apiObject.endpoint = id > 0 ? `stock_item/update` : `stock_item/save`;
  apiObject.body = body;
  return await ApiService.callApi(apiObject);
}
// stock item get all - with pagination
// /gets/{page_no}/{page_count}/{is_desc}
export async function getAll(page) {
  const apiObject = {};
  apiObject.method = 'GET';
  apiObject.authentication = true;
  apiObject.endpoint = `stock_item/gets/${page}/10/true`;
  apiObject.body = null;
  return await ApiService.callApi(apiObject);
}
// get all - without pagination
export async function getAllList() {
  const apiObject = {};
  apiObject.method = 'GET';
  apiObject.authentication = true;
  apiObject.endpoint = `stock_item/getStockItemsList`;
  apiObject.body = null;
  return await ApiService.callApi(apiObject);
}
// stock item qty - top-up
export async function topUpQty(body) {
  const apiObject = {};
  apiObject.method = 'POST';
  apiObject.authentication = true;
  apiObject.endpoint = `stock_qty/save`;
  apiObject.body = body;
  return await ApiService.callApi(apiObject);
}
// item delete
export async function deleteItem(id) {
  const apiObject = {};
  apiObject.method = 'DELETE';
  apiObject.authentication = true;
  apiObject.endpoint = `stock_item/delete/${id}`;
  apiObject.body = null;
  return await ApiService.callApi(apiObject);
}
// Stock Item QTY- get table - /gets/{page_no}/{page_count}/{is_desc}/{is_item_wise}/{stock_Item_Id}
// history
export async function getHistory(page, itemId) {
  const apiObject = {};
  apiObject.method = 'GET';
  apiObject.authentication = true;
  apiObject.endpoint = `stock_qty/gets/${page}/10/true/true/${itemId}`;
  apiObject.body = null;
  return await ApiService.callApi(apiObject);
}

// Supervisor =====

// supervisor request get all
// /gets/{page_no}/{page_count}/{is_desc}/{is_site_wise}/{site_id}
export async function supervisorReqGetAll(page) {
  const apiObject = {};
  apiObject.method = 'GET';
  apiObject.authentication = true;
  apiObject.endpoint = `supervisor_item_request/gets/${page}/10/true/false/0`;
  apiObject.body = null;
  return await ApiService.callApi(apiObject);
}
// Supervisor Item Request(admin panel) -update ** WAIT, REJECT, APPROVE
export async function manageRequest(body) {
  const apiObject = {};
  apiObject.method = 'PUT';
  apiObject.authentication = true;
  apiObject.endpoint = `supervisor_item_request/update`;
  apiObject.body = body;
  return await ApiService.callApi(apiObject);
}
// Supervisor Item Request - /delete/{id}
export async function removeRequest(id) {
  const apiObject = {};
  apiObject.method = 'DELETE';
  apiObject.authentication = true;
  apiObject.endpoint = `supervisor_item_request/delete/${id}`;
  apiObject.body = null;
  return await ApiService.callApi(apiObject);
}
// Stock Item Allocation- Supervisor TABLE - /gets/{siteId}/{page_no}/{page_count}/{is_desc}
export async function supervisorItemAllocations(page) {
  const apiObject = {};
  apiObject.method = 'GET';
  apiObject.authentication = true;
  apiObject.endpoint = `stock_item_allocation/gets/${localStorage.getItem(ADMIN_ID)}/${page}/10/true`;
  apiObject.body = null;
  return await ApiService.callApi(apiObject);
}

//Stock Item Allocation- Supervisor -approved request -update
export async function itemAllocationUpdate(body) {
  const apiObject = {};
  apiObject.method = 'POST';
  apiObject.authentication = true;
  apiObject.endpoint = `stock_item_allocation/update`;
  apiObject.body = body;
  return await ApiService.callApi(apiObject);
}

// reject / remove stock item allocation
export async function reject(id) {
  const apiObject = {};
  apiObject.method = 'DELETE';
  apiObject.authentication = true;
  apiObject.endpoint = `stock_item_allocation/delete/${id}`;
  apiObject.body = null;
  return await ApiService.callApi(apiObject);
}

// supervisor_item_request/save
export async function manageSupervisorItemRequest(body, id) {
  const apiObject = {};
  apiObject.method = id > 0 ? 'PUT':'POST';
  apiObject.authentication = true;
  apiObject.endpoint = id > 0 ? `supervisor_item_request/supervisor_update`:`supervisor_item_request/save`;
  apiObject.body = body;
  return await ApiService.callApi(apiObject);
}



// real-time data
export async function realTimeData(page, cleanerId, siteId){
  let URL = `attendance/get/${page}/10/true/${cleanerId ? cleanerId : 0}/${siteId ? siteId : 0}`;
  // if(localStorage.getItem(USER_TYPE) === MANAGER_ROLE) URL = `attendance/get/${page}/10/true/${cleanerId ? cleanerId : 0}/${siteId ? siteId : 0}/${localStorage.getItem(ADMIN_ID)}`
  if(localStorage.getItem(USER_TYPE) === MANAGER_ROLE) URL = `attendance/getAttendanceByManager/${page}/10/true/${cleanerId ? cleanerId : 0}/${siteId ? siteId : 0}/${localStorage.getItem(ADMIN_ID)}`
  const apiObject = {};
  apiObject.method = 'GET';
  apiObject.authentication = true;
  // apiObject.endpoint = `attendance/gets/${page}/10/true`;
  apiObject.endpoint = URL;
  apiObject.body = null;
  return await ApiService.callApi(apiObject);
}
// get all pending stock counts for supervisor
export async function getPendingStockCounts() {
  const apiObject = {};
  apiObject.method = 'GET';
  apiObject.authentication = true;
  apiObject.endpoint = `stock_item_allocation/get/non_action_request_count/${localStorage.getItem(ADMIN_ID)}`;
  apiObject.body = null;
  return await ApiService.callApi(apiObject);
}
// [Stock Delivery Log] bulk-save
export async function bulkSave(body, id) {
  const apiObject = {};
  apiObject.method = 'POST';
  apiObject.authentication = true;
  apiObject.endpoint = `stock_delivery_log/bulk-save`;
  apiObject.body = body;
  return await ApiService.callApi(apiObject);
}

// [Delivery Log] /getAll/{page_no}/{page_count}/{is_desc}/{start}/{end}/{isSiteWise}/{siteId}
export async function getAllDeliveryLogs(page, siteId, dates) {
  const apiObject = {};
  apiObject.method = 'GET';
  apiObject.authentication = true;
  apiObject.endpoint = `stock_delivery_log/getAll/${page}/10/true/${dates[0]}/${dates[1]}/${(!!siteId)}/${siteId ? siteId : 0}`;
  apiObject.body = null;
  return await ApiService.callApi(apiObject);
}
// remove
export async function removeLogs(id) {
  const apiObject = {};
  apiObject.method = 'DELETE';
  apiObject.authentication = true;
  apiObject.endpoint = `stock_delivery_log/delete/${id}`;
  apiObject.body = null;
  return await ApiService.callApi(apiObject);
}
