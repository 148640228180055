import * as actionTypes from './actionType';
import {ADMIN_ID, USER_EMAIL, USER_IMAGE, USER_NAME} from "../../../configs/constant";

const initialState = {
  showMessage:null,
  isError:null,

  // - chat store -
  // selectChat: {
  //   fullName:"Kisandu Athukorala",
  //   student: {
  //     email:"kisanduathukorala@gmail.com",
  //     studentId:100
  //   }
  // },

  selectChat: null,

  teacherFindByIdSuccess: {
    result: {
      email:localStorage.getItem(USER_EMAIL),
      teacherId:parseInt(localStorage.getItem(ADMIN_ID)),
      fullName: localStorage.getItem(USER_NAME),
      profilePicUrl: localStorage.getItem(USER_IMAGE)
    }
  },
  teacherFindByIdLoading: null,
  teacherFindByIdFailed: null,
  // selectedUser: {
  //   chat: "abc"
  // },
  selectedUser: {},
  userProfile: {

  },
  getAllCurrentYearClassStudentByTeacherIdSuccess: {result: []},
  getAllCurrentYearClassStudentByTeacherIdFailed: [],
  getAllCurrentYearClassStudentByTeacherIdLoading: [],

  contacts: [],
};

const reducer=(state=initialState,action)=>{
  // console.log("\n\naction.type",action.type)
  // console.log("action.value",action.value)
  switch(action.type){

    case actionTypes.IS_ERROR:
      return {
        ...state,
        isError: action.value
      };
    case actionTypes.SHOW_MESSAGE:
      return {
        ...state,
        showMessage: action.value
      };

    case actionTypes.MESSAGE_OBJ:
      return {
        ...state,
        showMessage:action.value.showMessage,
        isError:action.value.isError
      };
    case actionTypes.RESET_MESSAGE:
      return {
        ...state,
        showMessage:null,
        isError:null
      };
    case actionTypes.GET_CHAT_CONTACTS:
      return { ...state, contacts: action.value }

    case actionTypes.CHAT_HISTORY:
      return { ...state, selectedUser: action.value }
    case actionTypes.SELECT_CHAT:
      return { ...state, selectChat: action.value }

    default:
      return state;
  }
};

export default reducer;
