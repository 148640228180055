import React,{Component} from 'react';
import {getAllCleaners} from "../../../../services/employee";
import {Checkbox, Dropdown} from "semantic-ui-react";
import {Button, Col} from "reactstrap";
import * as commonFunc from "../../../../utility/commonFunc";
import * as actionCreator from "../../../../store/domain/spinner/action";
import {connect} from "react-redux";
import swal from "sweetalert";
import {addNewCleaner} from "../../../../services/report";


class App extends Component {
  state = {
    cleaners:[], cleaner:[],prevCleaners:[],files:[], isCleaner: true, isDownload: false
  }
  getAllCleaners = () => {
    this.props.spinnerHandler(true);
    getAllCleaners().then((response) => {
      if (response.code === "200") {
        let managers = [];
        response.result.map(obj => {
          managers.push({value: obj.userId, text:`${obj.firstName} ${obj.lastName}`, description: obj.email ? obj.email : ""})
        })
        this.setState({cleaners: managers })
      }
      this.props.spinnerHandler(false)
    })
  }
  componentDidMount() {
    let {isTemp, editObj} = this.props;
    this.setState({prevCleaners: editObj?.userList ?? []})
    if(!isTemp) this.getAllCleaners();
  }

  dropdownHandler = (status) => (e, {value}) => {
    let {prevCleaners} = this.state;
    if(status === "cleaner") {
      let isExist = false;
      value.map(cleanerId => {
        let isFindIndex = prevCleaners.findIndex(obj => obj === cleanerId);
        if(isFindIndex !== -1) {
          isExist = true;
          return commonFunc.notifyMessage("This user already exist")
        }
      })
      if(!isExist) this.setState({[status]:value})
    }else{
      this.setState({[status]:value})
    }
  }
  imagesHandler = (fileName, file) => {
    let files = this.state.files;
    if (files.length === 5) {
      commonFunc.notifyMessage("Limit exceeded!")
      return;
    }
    files.push({fileName: fileName, file: file})
    this.setState(files);
  }
  removeFileHandler =(id)=>{
    let files = this.state.files;
    files.splice(id,1);
    this.setState(files);
  }
  validateHandler = () => {
    let {cleaners, cleaner, isCleaner} = this.state;
    isCleaner && cleaner.length === 0 ? commonFunc.notifyMessage("Cleaner(s) cannot be empty") :
        // files.length === 0 ? commonFunc.notifyMessage("Please select a PDF") :
            swal({
              title: commonFunc.sureTxt, closeOnClickOutside: false,
              buttons: {cancel: 'No', dangerMode: {text: "Yes", value: "action", className: "okay-btn"}}
            })
              .then(async (value) => {
                switch (value) {
                  case "action":
                    this.submitHandler();
                    break;
                  default:
                }
              });
  }

  submitHandler = () => {
    let {editObj} = this.props;
    let {cleaner} = this.state;

    let formData = new FormData();
    formData.append('reportId', editObj?.reportId);
    formData.append('cleanerIdList', cleaner.join(","));

    this.props.spinnerHandler(true);
    addNewCleaner(formData).then(res => {
      this.props.spinnerHandler(false);
      if(res.code === "200") {
        this.props.closeHandler(res.desc);
      }else{
        commonFunc.notifyMessage(res.desc, res.code === "200" ? 1 : 0);
      }
    })
  }

  render() {
    let {cleaners, cleaner, isCleaner} = this.state;
    return (
      <div className={"w-100"}>
        <Col md={12} className={"wrap"}>
          <Checkbox label={"Notify to cleaner(s)"} className={'w-100_ mb-1'} checked={true}/>
        </Col>
        {isCleaner ? <Col md={12} className={"wrap multiple-dropdown-wrapper mt-2"}>
          <Dropdown placeholder='' fluid multiple={true} search selection options={cleaners} value={cleaner}
                    onChange={this.dropdownHandler('cleaner')} selectOnBlur={false}/>
        </Col>: null}

        <Col md={12} className={"mt-1"}>
          <div className="d-flex justify-content-center center cmn-btn-wrapper mt-0 mb-1">
            <Button.Ripple color="primary" type="submit" className={"cmn-gradient-bg"} onClick={this.validateHandler}>
              Submit
            </Button.Ripple>
          </div>
        </Col>
      </div>
    );
  }
}
const options = {
  filename: "using-function.pdf",
  page: {
    margin: 20
  }
};
const mapDispatchToProps = (dispatch) => ({
  spinnerHandler: data => dispatch(actionCreator.spinnerHandler(data))
});

export default connect(null, mapDispatchToProps)(App);
