
export const SHOW_MESSAGE = "SHOW_MESSAGE";
export const RESET_MESSAGE = "RESET_MESSAGE";
export const IS_ERROR = "IS_ERROR";
export const MESSAGE_OBJ = "MESSAGE_OBJ";
// chat section
export const SEND_MSG = "SEND_MSG";
export const SEND_MSG_NOTIFICATION = "SEND_MSG_NOTIFICATION";

export const USER_PROFILE = "USER_PROFILE";
export const CHAT_CONTACTS = "CHAT_CONTACTS";
export const GET_CHAT_CONTACTS = "GET_CHAT_CONTACTS";

export const SELECT_CHAT = "SELECT_CHAT";
export const CHAT_HISTORY = "CHAT_HISTORY";
