import ApiService from './apiService';

export async function loginUser(userCredentials) {
  const apiObject = {};
  apiObject.method = 'POST';
  apiObject.authentication = false;
  apiObject.endpoint = 'auth/signIn';
  apiObject.body = userCredentials;
  // apiObject.basePath = "signIn";
  apiObject.type = "AUTH";
  return await ApiService.callApi(apiObject);
}

export async function changePassword(obj) {
  const apiObject = {};
  apiObject.method = 'POST';
  apiObject.authentication = true;
  apiObject.endpoint = 'auth/changePassword';
  apiObject.body = obj;
  return await ApiService.callApi(apiObject);
}

export async function loginSave(obj) {
  const apiObject = {};
  apiObject.method = 'POST';
  apiObject.authentication = true;
  apiObject.endpoint = 'log/save';
  apiObject.body = obj;
  return await ApiService.callApi(apiObject);
}
export async function renewToken() {
  const apiObject = {};
  apiObject.method = 'POST';
  apiObject.authentication = true;
  apiObject.endpoint = 'auth/refreshToken';
  apiObject.body = {};
  apiObject.type = "RENEW_TOKEN";
  return await ApiService.callApi(apiObject);
}
//Notification
export async function updateUserFcmToken(obj) {
  const apiObject = {};
  apiObject.method = 'POST';
  apiObject.authentication = true;
  apiObject.endpoint = 'fcm/updateUserFcmToken';
  apiObject.body = obj;
  return await ApiService.callApi(apiObject);
}
// fcm notification send
export async function fcmNotification(obj) {
  const apiObject = {};
  apiObject.method = 'POST';
  apiObject.authentication = true;
  apiObject.endpoint = 'fcm/send';
  apiObject.body = obj;
  return await ApiService.callApi(apiObject);
}
