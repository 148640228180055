import React, {useState} from "react"
import {Modal, ModalBody, ModalHeader, Navbar} from "reactstrap"
import classnames from "classnames"
import NavbarBookmarks from "./NavbarBookmarks"
import NavbarUser from "./NavbarUser"
// import CorporateImage from '../../../assets/img/views/profile/pro-pic.png';
import './Navbar.scss';
// import connect from "react-redux/es/connect/connect";
import Clock from 'react-live-clock';
import {USER_EMAIL, USER_NAME, USER_TYPE} from "../../../configs/constant";
import {userTypeOptions} from "../../../utility/dropdownConstant";
import {connect, useDispatch} from "react-redux";
import {Bell} from "react-feather";
import SendPdf from "../../../views/pages/more-details/new/send-pdf-cleaners-only";
import NotificationLayer from "./NotificationLayer";
import {updateNotificationSeenStatus} from "../../../services/notification";

const ThemeNavbar = props => {
  const colorsArr = [ "primary", "danger", "success", "info", "warning", "dark"]
  const navbarTypes = ["floating" , "static" , "sticky" , "hidden"]

  const [notification, setNotification] = useState(false)

  let userType = localStorage.getItem(USER_TYPE);
  userType = userType ? userTypeOptions.find(obj => obj.value === userType).text : "";

  const notificationClickHandler = async () => {
    setNotification(true)
    await updateNotificationSeenStatus().then(res => {
      if(res.code === "200") document.getElementById("notification-id").style.display = 'none'
    })
  }

  return (
    <React.Fragment>
      <div className="content-overlay" />
      <div className="header-navbar-shadow" />
      <Navbar
        className={classnames(
          "header-navbar navbar-expand-lg navbar navbar-with-menu navbar-shadow",
          {
            "navbar-light": props.navbarColor === "default" || !colorsArr.includes(props.navbarColor),
            "navbar-dark": colorsArr.includes(props.navbarColor),
            "bg-primary":
              props.navbarColor === "primary" && props.navbarType !== "static",
            "bg-danger":
              props.navbarColor === "danger" && props.navbarType !== "static",
            "bg-success":
              props.navbarColor === "success" && props.navbarType !== "static",
            "bg-info":
              props.navbarColor === "info" && props.navbarType !== "static",
            "bg-warning":
              props.navbarColor === "warning" && props.navbarType !== "static",
            "bg-dark":
              props.navbarColor === "dark" && props.navbarType !== "static",
            "d-none": props.navbarType === "hidden" && !props.horizontal,
            "floating-nav":
              (props.navbarType === "floating" && !props.horizontal) || (!navbarTypes.includes(props.navbarType) && !props.horizontal),
            "navbar-static-top":
              props.navbarType === "static" && !props.horizontal,
            "fixed-top": props.navbarType === "sticky" || props.horizontal,
            "scrolling": props.horizontal && props.scrolling

          }
        )}
      >
        <div className="navbar-wrapper">
          <div className={"sec-data"}>
            {/*<p><span className={"bold"}>Environment: </span><span>LIVE</span></p>*/}
            <p><span className={"bold"}>Server Time: </span><span><Clock format={'DD MMM, hh:mm A'} ticking={true} /></span></p>
            <p><span className={"bold"}>User: </span><span>{`${localStorage.getItem(USER_NAME)} (${localStorage.getItem(USER_EMAIL)})`}</span></p>
            <p><span className={"bold"}>User Role: </span><span>{userType}</span></p>
            {props.ip ? <p><span className={"bold"}>Client IP: </span><span>{props.ip}</span></p>: null}
          </div>
          <div className="navbar-container content">
            <div
              className="navbar-collapse d-flex justify-content-between align-items-center"
              id="navbar-mobile"
            >
              <div className="bookmark-wrapper">
                <NavbarBookmarks
                  sidebarVisibility={props.sidebarVisibility}
                  handleAppOverlay={props.handleAppOverlay}
                />
              </div>
              <div className={'d-flex align-items-center'}>
                <div className={'mr-2 bell-wrapper'} >
                  <div className={'bell-none'} id={'notification-id'}>
                    0
                  </div>
                  <Bell onClick={notificationClickHandler} className={'cursor-pointer'} />
                </div>
                <NavbarUser
                  handleAppOverlay={props.handleAppOverlay}
                  changeCurrentLang={props.changeCurrentLang}
                  // userName={props.profile ? props.profile.profile.companyName ? props.profile.profile.companyName : "Loading .." : "Loading .."}
                  // userImg={props.profile ? props.profile.profile.profileImage ? props.profile.profile.profileImage : CorporateImage : CorporateImage}
                />
              </div>
            </div>
          </div>
        </div>
      </Navbar>
      {notification ? <Modal size={"sm"} isOpen={true}>
        <ModalHeader toggle={()=>setNotification(false)} className={"selector-wrapper inline-flex"}>
          Notifications
        </ModalHeader>
        <ModalBody className="modal-dialog-centered">
          <NotificationLayer />
        </ModalBody>
      </Modal>:null}
    </React.Fragment>
  )
}

// const mapStateToProps = (state) => ({
//   isSpinner: state.spinner.isSpinner,
// });
//
// export default connect(mapStateToProps, null)(ThemeNavbar);
export default ThemeNavbar;
